.p-container {
  height: min-content;
  margin: 0px 3vw 0px 3vw;
  padding-top: 6vh;
}

.p-img {
  width: 100%;
  height: 80vh;
  object-fit: cover;
}

.p-desc {
  margin-top: 4vh;
}

.p-list-desc {
  line-height: 123%;
}

.p-desc-alt {
  padding-left: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.p-desc-txt-alt {
  margin-top: 3vh;
}

.p-row-alt {
  border-bottom: 2px solid var(--black);
  padding-bottom: 6vh;
}

.p-year {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.p-btn {
  float: right;
}

.p-btn-alt {
  float: left;
  margin-top: 4vh;
}

.p-other-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 6vh
}

.p-list-row {
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
}

.p-list-row-first {
  border-top: 2px solid var(--black);
}

.p-list-title > a {
  text-decoration: none;
  color: var(--black);
}

.p-list-title > a:hover {
  color: var(--gray);
}

.p-list-date {
  font-family: 'NeueMontreal';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4vh;

  margin-left: 18px;

  color: var(--lightgray);

}

.p-list-btn {
  display: flex;
  justify-content: flex-end;
  font-size: 1.4vh;
}
.p-list-btn > a {
  text-decoration: none;
  color: var(--black);
}
.p-list-btn > a:hover {
  color: var(--gray);
}

@media screen and (max-width: 992px) {
  .p-container {
    margin: 0px 24px 0px 24px; 
    padding-top: 20px;
  }
  .p-img {
    height: 40vh;
  }
  .p-btn {
    float: left;
    margin-top: 60px;
  }
  .p-btn-alt {
    margin-top: 60px;
  }
  .p-desc-alt {
    padding-left: 0;
    margin-top: 4vh;
    padding-bottom: 3vh;
  }
  .p-desc {
    margin-top: 12px;
  }
  .p-row-alt {
    border-bottom: 2px solid var(--black);
    padding-bottom: 0;
  }
  .p-desc-txt-alt {
    margin-top: 0;
  }
  .p-list-desc {
    display: none;
  }
  .p-more {
    padding-top: 40px;
  }
  .p-list-btn {
    font-size: 14px;
  }
}